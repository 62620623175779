import './App.css';
import * as Sentry from '@sentry/react';

import toast, { Toaster } from 'react-hot-toast';
import { lazy, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Suspense, startTransition } from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
	Routes,
	Route,
} from 'react-router-dom';
import { useUserContext } from './context/userContext';
import mixpanel from './utils/mixpanel';
import { setUserId } from './redux/kharidBetch/slice';
import { useDispatch } from 'react-redux';

import MarketPlaceReq from './components/marketPlace/requirement';
import SpecialSuggestions from './components/UserSuggestions/SpecialSuggestions';
import { emptyQuizData } from './redux/quiz/slice';
import QuizCompleteScreen from './components/quiz/quizCompleted';
import store, { persistor } from './redux/store';
import GenericScreen3 from './components/genericScreen/index3.js';
import SchemePostCreate from './PostCreateRevanp/SchemePostCreate.js';

const OrderList = lazy(() => import('./sahaytacentre/OrderList.js'));
const CreateTicket = lazy(() => import('./sahaytacentre/CreateTicket.js'));
const TicketDetails = lazy(() => import('./sahaytacentre/TicketDetails.js'));
const Rating = lazy(() => import('./sahaytacentre/Rating.js'));
const AllTickets = lazy(() => import('./sahaytacentre/AllTickets.js'));
const Allitems = lazy(() => import('./creator-dashboard/Allitems.jsx'));
const Lottery = lazy(() => import('./creator-dashboard/Lottery.jsx'));
const ProductPage = lazy(() => import('./components/product-page/ProductPage'));
const Dashboard = lazy(() => import('./creator-dashboard/Dashboard.jsx'));
const ProductCategories = lazy(() => import('./components/Product-Main/ProductCategories'));
const UserSubmission = lazy(() => import('./components/UserSubmission/UserSubmission'));
const FinishSubmission = lazy(() => import('./components/UserSubmission/FinishSubmission'));
const CreatePost = lazy(() => import('./components/CreatePost'));
const KiranaReels = lazy(() => import('./components/autoplay-videos/index'));
const LuckyDraw = lazy(() => import('./components/LuckyDraw/index'));
const BillUpload = lazy(() => import('./components/Bills/billsUpload'));
const BrandsPage = lazy(() => import('./components/brandsPage'));
const BrandDetailPage = lazy(() => import('./components/brandsPage/BrandDetailPage'));
const SelfieUpload = lazy(() => import('./components/SelfieWithDiamond/selfieUpload'));
const Counterfeit = lazy(() => import('./components/counterfiet'));
const NonRetailerVerification = lazy(() =>
	import('./components/non-retailer-verification/NonRetailerVerification')
);
const TejiMandiCreate = lazy(() => import('./PostCreateRevanp/TejiMandiCreate.js'));
const Chat = lazy(() => import('./components/chat/Chat'));
const RenderWidget = lazy(() => import('./components/widget/RenderWidget'));
const ChatInfo = lazy(() => import('./components/chat/ChatInfo'));
const PostType = lazy(() => import('./PostCreateRevanp/PostType'));
const RevampPostCreate = lazy(() => import('./PostCreateRevanp/RevampPostCreate'));
const QuizLanding = lazy(() => import('./components/quiz/quizLanding'));
const GenericScreen = lazy(() => import('./components/genericScreen'));
const GenericScreen1 = lazy(() => import('./components/genericScreen/index1'));
const Ticket = lazy(() => import('./components/ticket'));
const Reactivation = lazy(() => import('./components/ReactivationPage'));
const PointsEarning = lazy(() => import('./components/incentive/PointsEarning'));
const BannerCategories = lazy(() => import('./components/bannerCreator/BannerCategories'));
const TemplateDesign = lazy(() => import('./components/bannerCreator/TemplateDesign'));
const Udhari = lazy(() => import('./components/udhari/BannerDesign'));
const Referral = lazy(() => import('./AppReferral/Referral'));
const MandiBhavReferral = lazy(() => import('./AppReferral/MandiBhavReferral'));
const MandiBhav = lazy(() => import('./components/mandiBhav/mandiBhav'));
const MandiBhavDetail = lazy(() => import('./components/mandiBhav/MandiBhavDetail'));
const ShopImgVerify = lazy(() => import('./components/shopImgVerify/ShopImgVerify'));
const UserSuggestions = lazy(() => import('./components/UserSuggestions/UserSuggestions'));
const UserSuggestionIntro = lazy(() => import('./components/UserSuggestions/UserSuggestionIntro'));
const LandingPage = lazy(() => import('./components/landingPage/LandingPage'));
const UserPosts = lazy(() => import('./PostCreateRevanp/UserPosts'));
const LeaderBoard = lazy(() => import('./components/quiz/quizLeaderBoard'));
const SaleIncreaseSurvey = lazy(() => import('./components/kiranathon/SaleIncreaseSurvey'));
const SaleIncreaseSurvey2 = lazy(() => import('./components/kiranathon/SaleIncreaseSurvey2'));
const MarketPlace = lazy(() => import('./components/marketPlace/marketPlace'));
const MarketPlaceDetails = lazy(() => import('./components/marketPlace/marketPlaceDetail'));
const MarketPlace2Details = lazy(() => import('./components/marketPlace/marketPlace2'));
const SujhavDe = lazy(() => import('./components/marketPlace/sujhavDe'));
const Survey = lazy(() => import('./components/survey/index.js'));
const PostCategory = lazy(() => import('./components/marketPlace/PostCategory'));
const Upi = lazy(() => import('./components/payment/upi'));
const Payment = lazy(() => import('./components/payment'));
const Celebration = lazy(() => import('./components/ticket/celebrationScreen'));
const GenericScreen2 = lazy(() => import('./components/genericScreen/index2'));
const GenericScreen4 = lazy(() => import('./components/genericScreen/index4'));
const GenericScreen5 = lazy(() => import('./components/genericScreen/index5'));
const SurveyStart = lazy(() => import('./components/survey/startScreen/index.js'));
const SurveyStartNew = lazy(() => import('./components/survey/startScreen/index2.jsx'));
const KiranaTv = lazy(() => import('./components/kiranaTv/index.tsx'));
const KiranaTvDetail = lazy(() => import('./components/kiranaTv/kiranaTvDetail/index.tsx'));
const KiranaTvVideoList = lazy(() => import('./components/kiranaTv/kiranaTvVideoList/index.tsx'));
const StudyDemo = lazy(() => import('./components/survey/study-demo/index.js'));
const Pincode = lazy(() => import('./components/pincode/index.jsx'));
const BrandDeals = lazy(() => import('./creator-dashboard/BrandDealStatus.jsx'));
const Sahayta = lazy(() => import('./components/sahayta/index.js'));
const QuizComponent = lazy(() => import('./components/quiz'));
const SahaytaCentre = lazy(() => import('./sahaytacentre/index.js'));

const excludedEndpoints = [
	'/nonretailer/verification',
	'/nonretailer/verification/',
	'/nonretailer/',
	'/nonretailer',
];

function App() {
	const dispatch = useDispatch();
	const { setUserDetails } = useUserContext();
	const search = useLocation().search;
	const userId = new URLSearchParams(search).get('userId');
	// const userId = useSelector(userIdSelector);

	useEffect(() => {
		const pathname = window?.location?.pathname;
		if (!excludedEndpoints.includes(pathname)) {
			const data = userId ?? window.sessionStorage.getItem('data');
			const webengageLicense = process.env.REACT_APP_WEBENGAGE_LICENSE;
			mixpanel.identify(data);
			window.webengage.init(webengageLicense);
			window.webengage.user.login(data);

			dispatch(setUserId(data));
		}
	}, [dispatch, setUserDetails, userId]);

	useEffect(() => {
		persistor.purge();
		dispatch(emptyQuizData());

		//return () => localStorage.setItem('initQuizFetch', '0');
	}, [dispatch]);

	return (
		<>
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route path="/" element={<ProductCategories />} />
					<Route path="/details" element={<ProductPage />} />
					<Route path="/videos" element={<KiranaReels />} />
					{/* <Route path="/luckydraw" element={<LuckyDraw/>} /> */}
					<Route path="/usersubmission/finishsubmission" element={<FinishSubmission />} />
					<Route path="/usersubmission" element={<UserSubmission />} />
					<Route path="/shopImgVerify" element={<ShopImgVerify />} />
					<Route path="/post/create" element={<CreatePost />} />
					<Route path="/revampPost/type" element={<PostType />} />
					<Route path="/revampPost/create" element={<RevampPostCreate />} />
					<Route path="/revampPost/create/scheme" element={<SchemePostCreate />} />
					<Route path="/revampPost/create/tejimandi" element={<TejiMandiCreate />} />
					<Route path="/revampPost/userPosts" element={<UserPosts />} />
					<Route path="/mandi/detail" element={<MandiBhavDetail />} />
					<Route path="/luckydraw" element={<LuckyDraw />} />
					<Route path="/billUpload" element={<BillUpload />} />
					<Route path="/reactivation" element={<Reactivation />} />
					<Route path="/reactivation/landingPage" element={<LandingPage />} />
					<Route path="/brand" element={<BrandsPage />} />
					<Route path="/brand/details/:id" element={<BrandDetailPage />} />
					<Route path="/selfieWithDiamond" element={<SelfieUpload />} />
					<Route path="/counterfeit" element={<Counterfeit />} />
					<Route path="/quiz" element={<QuizComponent />} />
					<Route path="/quizComplete" element={<QuizCompleteScreen />} />
					<Route path="/genericScreen" element={<GenericScreen />} />
					<Route path="/genericScreen1" element={<GenericScreen1 />} />
					<Route path="/genericScreen2" element={<GenericScreen2 />} />
					<Route path="/genericScreen3" element={<GenericScreen3 />} />
					<Route path="/genericScreen4" element={<GenericScreen4 />} />
					<Route path="/genericScreen5" element={<GenericScreen5 />} />

					<Route path="/study-demo" element={<StudyDemo />} />
					<Route path="/pincode/:brandID" element={<Pincode />} />

					<Route path="/ticket" element={<Ticket />} />
					<Route path="/ticketRedeemed" element={<Celebration />} />
					<Route path="/payment" element={<Payment />} />
					<Route path="/upi" element={<Upi />} />
					<Route path="/quizStart" element={<QuizLanding />} />
					<Route path="/survey" element={<Survey />} />
					<Route path="/surveyStart" element={<SurveyStart />} />
					<Route path="/surveyStartNew" element={<SurveyStartNew />} />
					<Route path="/kiranatv" element={<KiranaTv />} />
					<Route path="/kiranatvDetails/:id" element={<KiranaTvDetail />} />
					<Route path="/kiranaVideoList" element={<KiranaTvVideoList />} />

					<Route path="/leaderBoard" element={<LeaderBoard />} />
					<Route path="/mandi/:city" element={<MandiBhav />} />
					<Route path="/marketPlace" element={<MarketPlace />} />
					<Route path="/marketPlace/requirement" element={<MarketPlaceReq />} />
					<Route path="/marketPlace/postCategory" element={<PostCategory />} />
					<Route path="/marketPlace/detail" element={<MarketPlaceDetails />} />
					<Route path="/marketPlace2/detail" element={<MarketPlace2Details />} />
					<Route path="/nonretailer/verification" element={<NonRetailerVerification />} />
					<Route path="/widget/:widgetId" element={<RenderWidget />} />
					<Route path="/chat/:id/:dummySelfUserId" element={<Chat />} />
					<Route path="/chat/info/:id/" element={<ChatInfo />} />
					<Route path="/incentives/ways-to-earn-points" element={<PointsEarning />} />
					<Route path="/bannerCategories" element={<BannerCategories />} />
					<Route path="/bannerCategories/:templateName" element={<TemplateDesign />} />
					<Route path="/udhari/:templateName" element={<Udhari />} />
					<Route path="/referral" element={<Referral />} />
					<Route path="/mandi-bhav-referral" element={<MandiBhavReferral />} />
					<Route path="/userSuggestion" element={<UserSuggestionIntro />} />
					<Route path="/userSuggestion/survey" element={<UserSuggestions />} />
					<Route path="/sendSpecialPost" element={<SpecialSuggestions />} />
					<Route path="/saleIncrease/survey" element={<SaleIncreaseSurvey />} />
					<Route path="/saleIncrease2/survey" element={<SaleIncreaseSurvey2 />} />
					<Route path="/sujhavDe" element={<SujhavDe />} />
					<Route path="/creator-dashboard" element={<Dashboard />} />
					<Route path="/creator-dashboard/viewAll" element={<Allitems />} />
					<Route path="/creator-dashboard/brand-deals" element={<BrandDeals />} />
					<Route path="/creator-dashboard/lottery" element={<Lottery />} />
					<Route path="/sahayta" element={<Sahayta />} />
					<Route path="/sahayta-centre" element={<SahaytaCentre />} />
					<Route path="/sahayta-centre/all" element={<AllTickets/>}/>
					<Route path="/orders" element={<OrderList />} />
					<Route path="/sahayta-centre/create" element={<CreateTicket/>}/>
					<Route path="/sahayta-centre/details" element={<TicketDetails/>}/>
					<Route path="/sahayta-centre/rating" element={<Rating/>}/>
				</Routes>
			</Suspense>
			<Toaster />
			<ToastContainer
				position="top-right"
				autoClose={3000}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				theme="light"
			/>
		</>
	);
}

export default App;
